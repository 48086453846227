import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const hibafeltaras1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "Sokszor tapasztaltuk, hogy egy lefejlesztett szoftver használata közben hibák jönnek elő, de a fejlesztőket ekkor már nem lehet elérni",
  description: "Megpróbálhat együtt élni a hibával, de pont ez áll a növekedés és hatékonyság útjában. Pontosan ezért hoztuk létre hibafeltárás szolgáltatásunkat",
  displayXo: true,
  reverse: false,
  title: "Nem kell együtt élned a hibával",
  picture: "/images/services/feltaras_card_1.jpg"
}

export const hibafeltarasInfoCards = [
  hibafeltaras1,
]





