import { Service } from "../service-interface"
import { hibafeltarasInfoCards } from "../../service-info-card-interface/used_by/hibafeltaras-cards"

export const hibafeltaras : Service = {
  title: 'Hibafeltárás',
  description: "Hiba van a programban amit használsz? Vagy nem mersz egy új programra átállni, mert hiba esetén nem tudod kihez fordulj?",
  icon: "/images/bug.png",
  topimg: "/images/webshop_hibafeltaras.png",
  cards: hibafeltarasInfoCards,
  listPoints: [
    "Mi feltárjuk a hiba okát és megjavítjuk",
    "Minden sikeres termék mögött minőség és megbízhatóság áll"
  ]
}
