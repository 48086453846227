import * as React from "react"
import Layout from "../components/layout"
import ServiceTop from "../components/service-page/service-top"
import SkillsSlider from "../components/skills-slider/skills-slider"
import ServiceInfoCards from "../components/service-page/service-info-cards"
import MetaData from "../components/metadata"
import StructuredData from "../components/structured-data"
import { hibafeltaras } from "../interfaces/service/services/hibafeltaras"
import PageBottom from "../components/page-bottom/page-bottom"

const HibafeltarasPage = (props) => {
  return <Layout subHeader={false}>
    <MetaData title={'Hibafeltárás'} url={props.location.href} description={"Hiba van a programban amit használsz? Mi feltárjuk a hiba okát és megjavítjuk."}/>
    <StructuredData title={'Hibafeltárás'} url={props.location.href} description={"Hiba van a programban amit használsz? Mi feltárjuk a hiba okát és megjavítjuk."}/>
    <ServiceTop service={hibafeltaras} />
    <SkillsSlider />
    <ServiceInfoCards infoCards={hibafeltaras.cards} />
    <PageBottom/>
  </Layout>
}

export default HibafeltarasPage
